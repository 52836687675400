<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 기본정보 -->
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 저장 -->
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="equipInfo"
                :mappingType="saveType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 대상품명 -->
              <c-select
                isOld
                :disabled="isOld"
                type="edit"
                codeGroupCd="HHM_TYPE_CD"
                itemText="codeName"
                itemValue="code"
                name="machineryClassification"
                label="대상품명"
                v-model="equipInfo.machineryClassification"
              ></c-select>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                required
                :editable="editable"
                label="기계명"
                name="machineryName"
                v-model="equipInfo.machineryName">
              </c-text>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 용량 -->
              <c-text
                :editable="editable"
                label="용량(정격하중/적재하중)"
                name="volume"
                v-model="equipInfo.volume">
              </c-text>
            </div>
            <div v-if="isRobot" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                label="CELL NO."
                name="ewrNo"
                v-model="equipInfo.ewrNo">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                required
                :editable="editable"
                label="기계번호(관리번호)"
                name="passNumber"
                v-model="equipInfo.passNumber">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 형식번호(구간번호) -->
              <c-text
                required
                :editable="editable"
                label="형식번호(구간번호)"
                name="itemNo"
                v-model="equipInfo.itemNo">
              </c-text>
            </div>
            <div v-if="isConveir" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                suffix="m"
                type="number"
                :editable="editable"
                label="길이"
                name="meterLength"
                v-model="equipInfo.meterLength">
              </c-text>
            </div>
            <div v-if="isConveir" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                suffix="kw"
                type="number"
                :editable="editable"
                label="모터정격용량"
                name="motorVolume"
                v-model="equipInfo.motorVolume">
              </c-text>
            </div>
            <div v-if="isConveir" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                suffix="kg/h"
                type="number"
                :editable="editable"
                label="운송능력"
                name="transportCapacity"
                v-model="equipInfo.transportCapacity">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                label="제조(제작)사"
                name="manufacturer"
                v-model="equipInfo.manufacturer">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :editable="editable"
                type="month"
                label="제조(제작)년월"
                name="manufactDate"
                v-model="equipInfo.manufactDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                type="month"
                :editable="editable"
                label="설치년월"
                name="emplacementMonth"
                v-model="equipInfo.emplacementMonth">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 유효기간 -->
              <c-datepicker
                :editable="editable"
                :range="true"
                label="LBL0002540"
                name="validPeriod"
                v-model="equipInfo.validPeriod">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                label="자율검사프로그램 인정번호"
                name="esignBasisRatedLoad"
                v-model="equipInfo.esignBasisRatedLoad">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 검사대상 여부 -->
              <c-select
                required
                :comboItems="targetCdItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                :editable="editable"
                label="검사대상 여부"
                name="inspectionSubjectFlag"
                v-model="equipInfo.inspectionSubjectFlag"
                @datachange="datachange">
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 검사주기 -->
              <c-select
                :editable="editable"
                :disabled="!(equipInfo.inspectionSubjectFlag === 'Y')"
                type="edit"
                codeGroupCd="MDM_EQUIP_CYCLE_CD"
                itemText="codeName"
                itemValue="code"
                label="LBL0002511"
                name="inspectionCycle"
                v-model="equipInfo.inspectionCycle">
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                required
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="equipInfo.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-process
                :editable="editable"
                label="단위공정"
                multiple="multiple"
                name="subProcessCd"
                v-model="equipInfo.subProcessCd">
              </c-process>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!-- 담당부서 -->
              <c-dept-multi type="edit" label="LBL0002501" name="departmentDeptCd" v-model="equipInfo.departmentDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :editable="editable"
                type="date"
                label="철거일"
                name="demolitionMonth"
                v-model="equipInfo.demolitionMonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!-- 사용여부 -->
              <c-checkbox
                :editable="editable"
                :isFlag="true"
                label="LBLUSEFLAG"
                name="useFlag"
                v-model="equipInfo.useFlag">
              </c-checkbox>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!-- 비고 -->
              <c-textarea
                :editable="editable"
                label="비고"
                name="remark"
                v-model="equipInfo.remark">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <div class="col-12">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable"
        label="안전인증, 자율인증서">
      </c-upload>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default() {
        return {
          isSubmit: '',
          taskClassCd: 'HAZARD_EQUIPMENT_INFO',
          taskKey: '',
        }
      }
    },
    attachInfo2: {
      type: Object,
      default() {
        return {
          isSubmit: '',
          taskClassCd: 'HAZARD_EQUIPMENT_PICTURE',
          taskKey: '',
        }
      }
    },
    equipInfo: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        equipmentTypeCd: '', 
        equipmentTypeName: '',
        equipmentName: '',
        plantCd: null,
        machineryClassification: null,
        itemNo: '',
        inspectionKindCd: null,
        manufacturer: '',
        manufactDate: '',
        emplacementPlace: '',
        emplacementMonth: '',
        demolitionMonth: '',
        passNumber: '',
        ewrNo: '',
        amount: '',
        esignBasisRatedLoad: '',
        esignBasisKind: '',
        inspCertificateIssuanceDate: '',
        lastInspectionDate: '',
        inspectionSubjectFlag: null,
        inspectionCycle: null,
        nextInspectionDate: '',
        reasonExemptionInspection: '',
        machineryName: '',
        gradeCd: null,
        departmentDeptCd: '',
        departmentUserId: '',
        relatedLaws: '',
        remark: '',
        regUserId: '',
        chgUserId: '',
        subProcessCd: '',
        useFlag: 'Y',
        protectDevice: '',
        volume: '',
        possibleDisaster: '',
        validStartDate: '',
        validEndDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      targetCdItems: [
        { code: "Y", codeName: "대상" },
        { code: "N", codeName: "비대상" },
      ], // 대상여부
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      checkUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      getUrl: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isRobot() {
      return this.equipInfo.machineryClassification === 'HTC000015' || this.equipInfo.machineryClassification === 'HTC000016'
        || this.equipInfo.machineryClassification === 'HTC000017' || this.equipInfo.machineryClassification === 'HTC000018'
    },
    isConveir() {
      return this.equipInfo.machineryClassification === 'HTC000020' || this.equipInfo.machineryClassification === 'HTC000021'
    },
    isOld() {
      return Boolean(this.popupParam.hhmHazardousMachineryId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.saveUrl = transactionConfig.sop.hhm.hazard.check.insert.url;
      this.checkUrl = selectConfig.sop.hhm.hazard.check.check.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.check.insert.url;
      this.updateUrl = transactionConfig.sop.hhm.hazard.check.update.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
    },
    saveInfo() {
      if (this.popupParam.hhmHazardousMachineryId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
            this.$http.url = this.checkUrl
            this.$http.type = 'GET';
            this.$http.param = this.equipInfo;
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: '사업장 내 이미 존재하는 기계번호(관리번호)입니다.', // 사업장 내 이미 존재하는 기계관리번호입니다.
                  type: 'warning', // success / info / warning / error
                });
                return;
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    if (this.equipInfo.validPeriod && this.equipInfo.validPeriod.length > 0) {
                      this.equipInfo.validStartDate = this.equipInfo.validPeriod[0];
                      this.equipInfo.validEndDate = this.equipInfo.validPeriod[1];
                    } else {
                      this.equipInfo.validStartDate = '';
                      this.equipInfo.validEndDate = '';
                    }
                    this.equipInfo.regUserId = this.$store.getters.user.userId;
                    this.equipInfo.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                  });
                }
            },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(equipInfo) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.hhmHazardousMachineryId) {
        this.$emit('changeStatus')
      }
        this.$emit('saveCallback', equipInfo.data)
      this.$set(this.attachInfo, 'taskKey', equipInfo.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$set(this.attachInfo2, 'taskKey', equipInfo.data)
      this.$set(this.attachInfo2, 'isSubmit', uid())
    },
    removeHazard() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.hhmHazardousMachineryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange() {
      if (!this.equipInfo.inspectionSubjectFlag || this.equipInfo.inspectionSubjectFlag === 'N') {
        this.equipInfo.inspectionKindCd = null;
        this.equipInfo.inspectionCycle = null;
      } 
    },
  }
};
</script>