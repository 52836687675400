var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLBASEINFO" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.equipInfo,
                                mappingType: _vm.saveType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInfo,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          isOld: "",
                          disabled: _vm.isOld,
                          type: "edit",
                          codeGroupCd: "HHM_TYPE_CD",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "machineryClassification",
                          label: "대상품명",
                        },
                        model: {
                          value: _vm.equipInfo.machineryClassification,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.equipInfo,
                              "machineryClassification",
                              $$v
                            )
                          },
                          expression: "equipInfo.machineryClassification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "용량(정격하중/적재하중)",
                          name: "volume",
                        },
                        model: {
                          value: _vm.equipInfo.volume,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "volume", $$v)
                          },
                          expression: "equipInfo.volume",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isRobot
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "CELL NO.",
                              name: "ewrNo",
                            },
                            model: {
                              value: _vm.equipInfo.ewrNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipInfo, "ewrNo", $$v)
                              },
                              expression: "equipInfo.ewrNo",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: "",
                          editable: _vm.editable,
                          label: "기계번호(관리번호)",
                          name: "passNumber",
                        },
                        model: {
                          value: _vm.equipInfo.passNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "passNumber", $$v)
                          },
                          expression: "equipInfo.passNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: "",
                          editable: _vm.editable,
                          label: "형식번호(구간번호)",
                          name: "itemNo",
                        },
                        model: {
                          value: _vm.equipInfo.itemNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "itemNo", $$v)
                          },
                          expression: "equipInfo.itemNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isConveir
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "m",
                              type: "number",
                              editable: _vm.editable,
                              label: "길이",
                              name: "meterLength",
                            },
                            model: {
                              value: _vm.equipInfo.meterLength,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipInfo, "meterLength", $$v)
                              },
                              expression: "equipInfo.meterLength",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isConveir
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "kw",
                              type: "number",
                              editable: _vm.editable,
                              label: "모터정격용량",
                              name: "motorVolume",
                            },
                            model: {
                              value: _vm.equipInfo.motorVolume,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipInfo, "motorVolume", $$v)
                              },
                              expression: "equipInfo.motorVolume",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isConveir
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "kg/h",
                              type: "number",
                              editable: _vm.editable,
                              label: "운송능력",
                              name: "transportCapacity",
                            },
                            model: {
                              value: _vm.equipInfo.transportCapacity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.equipInfo,
                                  "transportCapacity",
                                  $$v
                                )
                              },
                              expression: "equipInfo.transportCapacity",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "제조(제작)사",
                          name: "manufacturer",
                        },
                        model: {
                          value: _vm.equipInfo.manufacturer,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "manufacturer", $$v)
                          },
                          expression: "equipInfo.manufacturer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          type: "month",
                          label: "제조(제작)년월",
                          name: "manufactDate",
                        },
                        model: {
                          value: _vm.equipInfo.manufactDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "manufactDate", $$v)
                          },
                          expression: "equipInfo.manufactDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          type: "month",
                          editable: _vm.editable,
                          label: "설치년월",
                          name: "emplacementMonth",
                        },
                        model: {
                          value: _vm.equipInfo.emplacementMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "emplacementMonth", $$v)
                          },
                          expression: "equipInfo.emplacementMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          range: true,
                          label: "LBL0002540",
                          name: "validPeriod",
                        },
                        model: {
                          value: _vm.equipInfo.validPeriod,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "validPeriod", $$v)
                          },
                          expression: "equipInfo.validPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "자율검사프로그램 인정번호",
                          name: "esignBasisRatedLoad",
                        },
                        model: {
                          value: _vm.equipInfo.esignBasisRatedLoad,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "esignBasisRatedLoad", $$v)
                          },
                          expression: "equipInfo.esignBasisRatedLoad",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          required: "",
                          comboItems: _vm.targetCdItems,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          editable: _vm.editable,
                          label: "검사대상 여부",
                          name: "inspectionSubjectFlag",
                        },
                        on: { datachange: _vm.datachange },
                        model: {
                          value: _vm.equipInfo.inspectionSubjectFlag,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.equipInfo,
                              "inspectionSubjectFlag",
                              $$v
                            )
                          },
                          expression: "equipInfo.inspectionSubjectFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: !(
                            _vm.equipInfo.inspectionSubjectFlag === "Y"
                          ),
                          type: "edit",
                          codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                          itemText: "codeName",
                          itemValue: "code",
                          label: "LBL0002511",
                          name: "inspectionCycle",
                        },
                        model: {
                          value: _vm.equipInfo.inspectionCycle,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "inspectionCycle", $$v)
                          },
                          expression: "equipInfo.inspectionCycle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: "",
                          editable: _vm.editable,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.equipInfo.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "plantCd", $$v)
                          },
                          expression: "equipInfo.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                    },
                    [
                      _c("c-process", {
                        attrs: {
                          editable: _vm.editable,
                          label: "단위공정",
                          multiple: "multiple",
                          name: "subProcessCd",
                        },
                        model: {
                          value: _vm.equipInfo.subProcessCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "subProcessCd", $$v)
                          },
                          expression: "equipInfo.subProcessCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                    },
                    [
                      _c("c-dept-multi", {
                        attrs: {
                          type: "edit",
                          label: "LBL0002501",
                          name: "departmentDeptCd",
                        },
                        model: {
                          value: _vm.equipInfo.departmentDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "departmentDeptCd", $$v)
                          },
                          expression: "equipInfo.departmentDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          type: "date",
                          label: "철거일",
                          name: "demolitionMonth",
                        },
                        model: {
                          value: _vm.equipInfo.demolitionMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "demolitionMonth", $$v)
                          },
                          expression: "equipInfo.demolitionMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-checkbox", {
                        attrs: {
                          editable: _vm.editable,
                          isFlag: true,
                          label: "LBLUSEFLAG",
                          name: "useFlag",
                        },
                        model: {
                          value: _vm.equipInfo.useFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "useFlag", $$v)
                          },
                          expression: "equipInfo.useFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "비고",
                          name: "remark",
                        },
                        model: {
                          value: _vm.equipInfo.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipInfo, "remark", $$v)
                          },
                          expression: "equipInfo.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-upload", {
            attrs: {
              attachInfo: _vm.attachInfo,
              editable: _vm.editable,
              label: "안전인증, 자율인증서",
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }